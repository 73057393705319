export default {
  actions: {
  },
  mutations: {
  },
  state: {
    links: {
      registration: {
        isActive: {
          en: true,
          ru: true,
        },
        type: "path",
        name: "registration",
        link: "/registration-to-conference",
        styles: "color: #cc0000"
      },
      registrationNew: {
        isActive: {
          en: true,
          ru: true,
        },
        type: "download-link",
        name: "registrationNew",
        link: "registration-to-conference-Newlink",

      },
      informationLetter: {
        isActive: {
          en: false,
          ru: true,
        },
        type: "download-link",
        name: "information-letter",
        link: "information-letter-link",
      },
      requirements: {
        isActive: {
          en: true,
          ru: true,
        },
        type: "download-link",
        name: "requirements-for-reports",
        link: "requirements-for-reports-link",
      },
      templateArticles: {
        isActive: {
          en: true,
          ru: true,
        },
        type: "download-link",
        name: "template-articles",
        link: "template-articles-link",
      },
      payment_conferense: {
        isActive: {
          en: true,
          ru: true,
        },
        type: "outpath",
        name: "payment-for-participation-in-the-18th-conference",
        link: "https://nasledie-vvia.ru/payment-18-conference/"
      },
      programm_tsca: {
        isActive: {
          en: false,
          ru: true,
        },
        type: "download-link",
        name: "programm-tsca",
        link: "programm-tsca-link",
        styles: "color: #cc0000"
      },

      full_programm_tsca: {
        isActive: {
          en: false,
          ru: true,
        },
        type: "download-link",
        name: "full-programm-tsca",
        link: "full-programm-tsca-link"
      },

      examinationAct: {
        isActive: {
          en: false,
          ru: true,
        },
        type: "download-link",
        name: "examination-act",
        link: "examination-act-link",
      },

      resolution_tsca: {
        isActive: {
          en: false,
          ru: false,
        },
        type: "download-link",
        name: "resolution-tsca",
        link: "resolution-tsca-link",
      },

    },
  },
  getters: {
    getSidebarMenuItems(state) {
      return [
        //state.links.registration,
        state.links.programm_tsca,
        state.links.registrationNew,
        state.links.informationLetter,
        //state.links.requirements,
        state.links.templateArticles,
        //state.links.programm_tsca,
        //state.links.full_programm_tsca,
        //state.links.examinationAct,
        //state.links.payment_conferense,
        state.links.resolution_tsca
      ];
    },
  },
}
